.stimuli-tiles {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  &.orf-k-assessment {
    margin-bottom: 32px;
    border-top: 1px solid white;
  }
}

.stimuli-tiles--stories__title {
  color: $text-light;
  font-size: 42px;
  width: 80%;

  p {
    padding: 0 40px;
  }
}

.stimuli-tiles--stories__tiles {
  a {
    font-size: 1em;
  }
}
