.demo {
  width: 100%;
  padding: 0.8em 1em;
  background: rgba(147, 128, 108, 0.1);
  border-radius: 3px;
  font-size: 14px;
  font-family: "Andika", sans-serif;
}
.reinforcement-header {
  padding-bottom: 15px;
}

.grid {
  display: flex;
}

.grid-cell,
.g-cell {
  flex: 1;
}

/* With gutters */
.grid--gutters {
  margin: -1em 0 1em -1em;
}
.grid--gutters > .grid-cell,
.grid--gutters > .g-cell {
  padding: 1em 0 0 1em;
}

/* Alignment per row */
.grid--flex-cells > .grid-cell,
.grid--flex-cells > .g-cell {
  display: flex;
}
.grid--top {
  align-items: flex-start;
}
.grid--bottom {
  align-items: flex-end;
}
.grid--center {
  align-items: center;
  justify-content: center;
}
.grid--space-between {
  align-items: center;
  justify-content: space-between;
}
.grid--wrap {
  flex-wrap: wrap;
  align-content: flex-end;
}

.grid--column {
  flex-direction: column;
}
.grid--row {
  flex-direction: row;
}

.grid--wrap > .grid-cell,
.grid--wrap > .g-cell {
  flex: 0 auto;
  display: block;
}

/* Alignment per cell */
.grid-cell--top {
  align-self: flex-start;
}
.grid-cell--bottom {
  align-self: flex-end;
}
.grid-cell--center {
  align-self: center;
}

.grid--fit > .grid-cell {
  flex-grow: 1;
}

.grid--full > .grid-cell,
.grid--full > .g-cell {
  flex: 0 0 100%;
}

// Grid Width Utilities
.u-1of1 {
  flex: none !important;
}

.u-1of2 {
  width: 50% !important;
  flex: none !important;
}
.u-1of3 {
  width: 33.3333% !important;
  flex: none !important;
}
.u-1of4 {
  width: 25% !important;
  flex: none !important;
}
.u-2of3 {
  width: 66.6666% !important;
  flex: none !important;
}

.u-3of4 {
  width: 75% !important;
  flex: none !important;
}
