// SIZE
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin absolute($args) {
  @include position("absolute", $args);
}

@mixin fixed($args) {
  @include position("fixed", $args);
}

@mixin relative($args) {
  @include position("relative", $args);
}

// for rollover and active states of icons: supply the name of the icon(s), the width, the non-hover fill color and hover fill color

@mixin icon-hover($name, $width, $fill, $fillHover) {
  [class*="icon-#{$name}"] {
    width: $width;
    height: auto;
    // fill: $fill;
  }
  // &:hover,
  &.st-active {
    .icon-#{$name} {
      display: none;
    }
    .icon-#{$name}-filled {
      display: inline-block;
      // fill: $fillstagHover;
    }
  }
}

// TODO: move these variables out of this file
// -----------------------------------------------------------------------------
// !-- BREAKPOINTS
// -----------------------------------------------------------------------------

// Define width breakpoints
$breakpoints--width: (
  extraSmall: 400px,
  small: 600px,
  medium: 750px,
  large: 1000px,
  extraLarge: 1400px
);

// Define width breakpoints
$breakpoints--height: (
  small: 625px,
  medium: 700px,
  large: 800px
);

// -----------------------------------------------------------------------------
// !-- Respond To
//
// Add a height or width media query in one of two directions…up or down.
//
// respond-to($breakpointName, $type, $direction)
//
// $breakpointName: name that references height or width breakpoints maps
// $type: type of media query…either 'height' or 'width'
// $direction: direction in which to apply media query…'up' or 'down'
//
// Example:
//
// .div {
//   @include respond-to(large, width, up) {
//     background-color: purple;
//   };
// }
//
// Generates:
//
// .div {
//   @media (min-width: 940px) {
//     background-color: purple;
//   }
// }
//
// -----------------------------------------------------------------------------

@mixin respond-to($breakpointName, $type, $direction) {
  // Need to define variable before its set in the conditional statement below
  $value: "";

  // Retrieves and sets the $value from the key
  @if $type == width {
    // Use the width variable map
    $value: map-get($breakpoints--width, $breakpointName);
  } @else if $type == height {
    // use the height variable map
    $value: map-get($breakpoints--height, $breakpointName);
  } @else {
    @warn "Please define the type of media query…'height' or 'width'";
  }

  // If the key exists in the map
  @if $value != null {
    @if $direction == up {
      // Prints a media query based on the value
      @media (min-#{$type}: $value) {
        @content;
      }
    } @else if $direction == down {
      // Prints a media query based on the value
      @media (max-#{$type}: $value) {
        @content;
      }
    } @else {
      @warn "Please define a direction for this media query…'up' or 'down'.";
    }
  } @else {
    // If the key doesn't exist in the map
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoints}--#{type}`. "
        + "Please make sure it is defined in the `$breakpoints` map.";
  }
}

// @mixin respond-to($breakpointName, $direction: up) {
//   // Retrieves the value from the key
//   $value: map-get($breakpoint--width, $breakpointName);
//
//   // If the key exists in the map
//   @if $value != null {
//     @if $direction == up {
//       // Prints a media query based on the value
//       @media (min-width: $value) {
//         @content;
//       }
//     } @else if $direction == down {
//       // Prints a media query based on the value
//       @media (max-width: $value) {
//         @content;
//       }
//     }
//   } @else { // If the key doesn't exist in the map
//     @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
//         + "Please make sure it is defined in the `$breakpoints` map.";
//   }
// }
