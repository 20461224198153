.dialogEndSessionContainer p {
  color: white;
}

.dialogEndSessionContainer button {
  border: 2px solid white;
  background-color: $mastered;
}

.dialogEndSession__overlay {
  background-color: rgba(0, 0, 0, 0.54);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5000;
  left: 0;
  top: 0;
}

.dialogEndSession {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  z-index: 1000;
  top: 50%;
  left: 50%;
  margin-top: -70px;
  margin-left: -300px;
  p {
    margin: 0;
    text-align: center;
  }
}

.dialogEndSession__header {
  height: 80px;
  width: 600px;
  background-color: #26a69a;
  margin: 0;
  border-radius: 20px 20px 0 0;
}

.dialogEndSession__title {
  color: white;
  text-align: center;
  line-height: 1.5em;
  padding-top: 0.5em;
  font-family: $font-primary;
  font-size: 1em;
  font-weight: 300;
}

.dialogEndSession__main {
  height: 310px;
  clear: both;
  width: 100%;
  margin: auto;
}

.dialogEndSession__block {
  width: 33%;
  float: left;
  text-align: center;
  margin-top: 30px;
}

.dialogEndSession__image {
  background-color: #eeeeee;
  width: 50px;
  height: 35px;
  margin: auto;
}

.dialogEndSession__cancel-container {
  background-color: #eeeeee;
  height: 70px;
  bottom: 0;
  width: 300px;
  border-radius: 0 0 0 20px;
  padding: 0;
  text-align: center;
  float: left;
}

.dialogEndSession__ok-container {
  background-color: #ffd740;
  height: 70px;
  width: 300px;
  border-radius: 0 0 20px 0;
  padding: 0;
  float: left;
  text-align: center;

  .st-hidden + & {
    width: 600px;
    border-radius: 0 0 20px 20px;
  }
}

.dialogEndSession__cancel {
  color: rgba(0, 0, 0, 0.54);
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.dialogEndSession__ok {
  color: white;
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}
