.edit-archive__header {
  border-bottom: 1px solid $text-medium;
  display: inline-block;
  padding: 15px 0 15px 10px;
  width: 100%;

  span {
    display: inline-block;
    text-align: right;
  }
}

.stage--edit__archive {
  min-height: 100%;
  height: 100%;
  align-items: stretch;
}

.edit-archive__list {
  font-size: 12px;
  overflow: auto;
  text-transform: uppercase;

  li {
    padding: 10px 0 10px 10px;
    background: darken($iron, 10%);
    border-bottom: 1px solid $white;
    cursor: pointer;

    &.st-selected {
      background: $white;

      h4 {
        color: $orange;
      }
    }
  }

  p {
    display: block;
    margin: 0;
    text-transform: none;
  }

  h4 {
    font-family: $font-primary;
    font-size: 9px;
    margin: 0;

    .edit-notes__time {
      font-family: $font-primary;
    }
  }
}
