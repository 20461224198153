// RULE: Only @extend a class (or placeholder) in the same stylesheet (NOT ACROSS STYLES)
button,
.button,
%button {
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  font-family: $font-primary;
  min-height: 40px;
  border-radius: 6px;
  fill: $white;

  &.is--inactive {
    opacity: 0.4;
  }
}

%buttonStandard {
  font-family: $font-primary;
  color: $button-text-light;
  font-size: 1em;
  padding: 0 1em;
  width: 8.5em;
  text-transform: uppercase;

  @include respond-to(small, width, up) {
    padding: 0 1.5em;
    width: 10em;
  }

  @include respond-to(medium, width, up) {
    width: 11em;
  }

  @include respond-to(large, width, up) {
    width: 12em;
  }
}

.button--primary {
  @extend %button;
  @extend %buttonStandard;
  background: $primary;

  &:hover,
  &:focus {
    background: darken($primary, 5%);
  }
}

.button--secondary {
  @extend %button;
  @extend %buttonStandard;
  background: transparentize($black, 0.46);

  &:hover {
    background: $primary;
  }
}

.button--knockout {
  border: 1px solid $white;
  color: $text-light;

  &:hover,
  &:focus {
    border-color: $button-text-active;
    color: $button-text-active;
  }
}

.button--knockout--dark {
  border: 1px solid $black;
  color: $text-dark;

  &:hover,
  &:focus {
    border-color: $button-text-active;
    color: $button-text-active;
  }
}

.button--small {
  min-height: 30px;
  text-transform: uppercase;
}

.button--login {
  @extend .button;
  padding: 0;
  text-transform: uppercase;
}

.button--edit-notes {
  @extend .button--primary;
  margin-left: 20px;
  height: 50px;
  width: 150px;
}

.button--save-note,
.button--cancel-note,
.button--new-note,
.button--end-note {
  @extend .button--primary;
  margin-right: 2%;
}

.button--cancel-note,
.button--new-note,
.button--end-note {
  background-color: $gray-med;
}

.button--cancel-note,
.button--end-note {
  flex: 0;
}

.button--new-note {
  margin-right: 0;
}

.button--end-note {
  margin-left: auto;
}

.button--warning {
  @extend %button;
  background: $warning;
  color: $button-text-light;
  text-transform: uppercase;
  font-size: 18px;
  padding: 1% 2%;
  border-radius: 8px;
  margin-bottom: 4px; // magic number

  &.button--warning--inverse {
    background: $white;
    border: 1px solid $warning;
    color: $warning;
  }
}

.button--drawer-toggle {
  @extend %button;
  font-size: 26px;
  text-align: center;
  margin-right: 30px;
  z-index: 100;
  height: 50px;
  width: 50px;

  .icon-menu-toggle {
    width: 26px;
    height: auto;
  }
}

.button--close-student-edit {
  color: $orange;
  margin-left: auto;
  text-align: right;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
  border: 0;
  box-shadow: inset 1px -1px 0.5px rgba(0, 0, 0, 0.05);
}

.button--clear {
  margin-top: 15px;

  .icon-clear {
    width: 34px;
    height: 34px;
    margin: 10px auto 5px;
    fill: $gray-med;
  }
}

.button--text {
  font-family: $font-primary;
  font-size: 12px;
  display: block;
  color: $black;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;

  &:hover,
  &:active,
  &.st-active {
    color: $button-text-active;
  }
}

.button--reading-strategies-toggle {
  width: 16em;
  background-color: $bg-dark;
  border-radius: 0;
  font-size: 0.8em;
  margin-left: 20px;
}

.button--close {
  fill: $white;
  height: 35px;
  right: 5px;
  position: absolute;
  top: 5px;
  width: 35px;
  z-index: 200;

  &:before {
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }

  &.button--close--edit-student {
    right: 5px;
    left: auto;
  }

  .icon-close--overlay {
    bottom: 0;
    height: auto;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
  .icon-close {
    display: inline-block;
    height: auto;
  }
}

.currentStudent--tab {
  font-family: $font-primary;
  text-align: center;
  background-color: $panel-background;
  color: $text-light;
  letter-spacing: 0.1em;
  line-height: 2;
  cursor: pointer;
  margin-top: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.nextbutton__container {
  margin-bottom: -74px;
  margin-top: 56px;
  margin-right: 16px;
  z-index: 90;
  position: relative;
  float: right;
  border-radius: 5px;
  border: 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  height: 35px;
  width: calc(35px * 4);
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.nextButton__leftBlock {
  height: 35px;
  width: 35px;
  color: $text-light;
  font-size: 0.5em;
  text-align: center;
  line-height: 1;
  padding-bottom: 0;
  background-color: lighten($button-bg, 10%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: auto;
  float: left;
}

.nextButton__rightBlock {
  width: 105px;
  height: 35px;
  background-color: $button-bg;
  text-align: center;
  line-height: 2.15;
  color: $text-light;
  float: left;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.nextButton__leftBlock--text {
  padding-top: 9px;
}

.student-logout__container {
  display: flex;
  margin-left: 1em;
  margin-bottom: 0.5em;
}

.student-logout__link {
  color: black !important;
  font-family: $font-primary !important;
  font-size: 0.75em !important;
  margin-left: 0.3em !important;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 10px;
  &:before {
    content: 'x';
    border: 1px solid black;
    border-radius: 50%;
    height: 18px;
    width: 16px;
    display: inline-block;
    padding: 0px 0px 0 4px;
    line-height: 1;
  }
}

.orfBack-button {
  position: absolute;
  left: 0;
  bottom: 40%;
}

.orfNext-button {
  position: absolute;
  right: 0;
  bottom: 40%;
}

.add-tile__button {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 0.8em;
  padding: 3px;
  width: 60px;
  height: 40px;
  line-height: 32px;
  text-align: center;
  color: white;
  border-radius: 0 5px 5px 0;
  margin-top: 10px;
  margin-left: -3px;
  z-index: 500;
  position: relative;
  display: inline-block;

  &:hover {
    background-color: darken(rgba(0, 0, 0, 0.3), 5%);
  }
}

.clear-whiteboard__button {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 0.8em;
  padding: 5px;
  width: 82px;
  height: 40px;
  line-height: 32px;
  text-align: center;
  color: white;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  float: right;
  z-index: 500;
  position: relative;

  &:hover {
    background-color: darken(rgba(0, 0, 0, 0.3), 5%);
  }
}

.button--submit-reinforcement {
  @extend .button--primary;
  width: 35px;
  float: left;
  line-height: 33px;
  min-height: 35px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  padding: 0;
  font-size: 18px;
}

.button--display-notes {
  @extend .button--primary;
  position: absolute;
  bottom: 8%;
  width: 200px;
  left: 2%;
  float: left;
  line-height: 33px;
  min-height: 35px;
  border-radius: 5px;
  text-align: center;
  padding: 0;
  font-size: 18px;
}

.stage-reinforcement-text-input {
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 0;
  border-radius: 5px 0 0 5px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  float: left;
  height: 35px;
  padding: 3px;
  width: 118px;
}

.center-reinforcements {
  margin: 10px auto 0;
  display: flex;
  justify-content: center;
}

.js-buttonClear {
  margin: 20px auto 5px;
}

.add-tile__text-input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 5px 0 0 5px;
  margin-left: 10px;
  width: 100px;
  height: 40px;
  text-align: center;
}

.master-list__search {
  background: transparent;
  border-radius: 9px;
  border: 0;
  height: 21px;
  line-height: 21px;
  margin-top: -3px;
  padding: 2px 0 2px 10px;
  vertical-align: middle;
  width: 130px;
}

.search-bar__msw-list {
  background-color: white;
  border-radius: 9px;
  float: right;
  margin: 3px 3px 0 0;
}
