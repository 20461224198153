.orientationlock {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $yellow;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: $black;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  z-index: 100;
  font-weight: bold;

  span {
    display: block;
    position: relative;
    &:before {
      content: "";
      display: block;
      height: 150px;
      width: 250px;
      background-image: url("../img/orientationlock.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 auto 20px;
    }
  }
}
