$whiteboard-magnets-height: 55%;

.whiteboard {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.whiteboard__item {
  flex: 1;
  transition: flex-grow 1s, max-height 1s;

  &.is--collapsed {
    flex-grow: 0;
  }
}

.whiteboard__canvas {
  background-color: $whiteboard;
  overflow: hidden;

  &.is--rotated {
    transform: rotate(180deg);
  }
}

.whiteboard__canvas--standard {
  margin-top: 5px;
  clear: both;
}

.whiteboard__canvas--inverted {
  margin-bottom: 5px;
  justify-content: flex-end;

  .controlsWrapper {
    transform: rotate(180deg);
  }
}

.whiteboard__bank {
  display: flex;
  flex: 1 0 $whiteboard-magnets-height;
  max-height: $whiteboard-magnets-height;
  background-color: $magnet-bank;

  &.is--animating {
    overflow: hidden;
  }

  &.is--collapsed {
    overflow: hidden;
    max-height: 0;
    flex-basis: 0;
  }
}

.whiteboard__bank--inverted {
  flex-direction: row-reverse;
}

.reinforcement-activity__student-name {
  float: left;
  font-family: $font-primary;
  font-size: 0.8em;
  margin-left: 1em;
  margin-top: 1em;
  flex: 1 0;
}

.stimulus__sight-word-reinforcement {
  font-family: $font-primary;
  font-size: 90px;
  margin: auto;
  float: left;
  width: 100%;
  display: block;
  text-align: center;
}

.word-bank__word-bank-container {
  width: 100%;
  max-height: 345px;
  overflow: scroll;
  margin-bottom: 25px;
}

.word-bank__background {
  width: 100%;
  margin: 0;
  clear: both;
  float: left;
  height: 50%;
}

.word-bank__nav {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  float: left;
  background-color: rgba(0, 0, 0, 0.54);
}

.word-bank_nav-item {
  float: left;
  width: 21%;
  margin: 0 2%;
  padding: 1% 0;
  color: white;
  text-transform: uppercase;
  font-family: $font-primary;
  font-size: 0.8em;

  &:hover,
  :active {
    cursor: pointer;
    font-family: $font-primary;
    color: $yellow;
  }

  &.active {
    cursor: pointer;
    font-family: $font-primary;
    color: $yellow;
  }

  &.mastered,
  .active {
    &:hover {
      background-color: #eeeeee;
      color: $mastered;
    }
  }
  &.learning,
  .active {
    &:hover {
      background-color: #eeeeee;
      color: $primary;
    }
  }
  &.needs-work,
  .active {
    &:hover {
      background-color: #eeeeee;
      color: $purple;
    }
  }
}

.word-bank__word-choice {
  font-family: $font-primary;
  float: left;
  font-size: 1.6em;
  width: 20%;
  margin: auto;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  
  #applicationContainer.synthetic [data-matrix="wp_4"] & {
    width: 50%;
  }

  &:hover {
    cursor: pointer;
  }

  &.mastered {
    color: #1de9b6;
  }
  &.learning {
    color: #ffca28;
  }
  &.needs_work {
    color: #ec407a;
  }
  #applicationContainer.synthetic &.needs_work {
    color: $needs-work-synthetic;
  }
  &.selected {
    background-color: #cccccc;
  }
}

.word-bank__flex {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
}
