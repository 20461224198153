.panelGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.panel {
  background-color: $settings-transparent-bg;
  margin: 2%;
  flex: 1 0 80%;

  @include respond-to(medium, width, up) {
    flex: 1 0 42%;
    max-width: 42%;
  }
}

.panel__content {
  .panel--toggle & {
    opacity: 0;
  }

  .panel--toggle.checked & {
    opacity: 1;
  }
}

.panel__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 60px; // each item should be the same height
  color: $white;
  padding: 1em 2em;

  & + & {
    border-top: 1px solid rgba(255,255,255,0.25);
  }

  & + .panel__subitem {
    border-top: 0;
    padding: 0 6em 1em;
  }
}

.panel__item--header {
  background-color: $settings-transparent-bg;
  padding: 1em 2em;
  cursor: pointer;
}

.panel__label {
  display: inline-block;
  font-family: $font-primary;
  font-weight: 300;

  .panel__item--header & {
    font-family: $font-primary;
    font-weight: 500;
  }
}

.group-auto-settings__description {
  line-height: 0;
  font-size: 0.6em;
  color: rgba(255, 255, 255, 0.4);
}

.panel__value {
  display: inline-block;
  font-family: $font-primary;
  font-weight: 300;
  font-size: 2em;
  cursor: pointer;
}

.panel__link {
  color: $link-settings;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}
