.orfLevel,
.orfReminder p {
  color: white;
}

.orfLevel__overlay,
.orfReminder__overlay {
  background-color: rgba(0, 0, 0, 0.54);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5000;
  left: 0;
  top: 0;
}

.orfLevel__title,
.orfReminder__title {
  font-family: $font-primary;
  font-size: 2em;
  color: white;
  text-align: center;
  line-height: 1em;
  padding-top: 0.5em;
}

.orfLevel p,
.orfReminder p {
  margin: 0;
  text-align: center;
}

.orfLevel {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  z-index: 1000;
  top: 35%;
  left: 50%;
  margin-top: -70px;
  margin-left: -450px;
}

.orfReminder {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  z-index: 1000;
  top: 25%;
  left: 45%;
  margin-top: -70px;
  margin-left: -300px;
}

.orfLevel__header,
.orfReminder__header {
  height: 70px;
  width: 750px;
  background-color: #26a69a;
  margin: 0;
  border-radius: 20px 20px 0 0;
}

.orfLevel__range {
  background-color: $yellow;
  height: 70px;
  bottom: 0;
  width: 750px;
  border-radius: 0 0 20px 20px;
  padding: 0;
  text-align: center;
  float: left;
}

.orfReminder__go-back {
  background-color: #eeeeee;
  height: 70px;
  bottom: 0;
  width: 300px;
  border-radius: 0 0 0 20px;
  padding: 0;
  text-align: center;
  float: left;
}

.orfReminder__close {
  color: rgba(0, 0, 0, 0.54);
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.orfReminder__continue {
  color: white;
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.orfReminder__to-assessment {
  background-color: #ffd740;
  height: 70px;
  width: 300px;
  border-radius: 0 0 20px 0;
  padding: 0;
  float: left;
  text-align: center;
}
.orfLevel__text,
.orfReminder__text {
  background-color: white;
  width: 600px;
  padding: 25px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.5em;
  font-family: $font-primary;
  line-height: 1.5em;
  cursor: pointer;
}

[data-dialog="orfFinish"] .orfReminder__text {
  width: 750px;
}

.orfStudentName {
  color: #26a69a;
}

.ORF-firstgrade__paragraph {
  margin: 0;
  &:first-of-type {
    padding-top: 18px;
  }
}

//// modification for adding a tile ///
.newtile {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 50px;
  font-size: 1em;
  font-family: $font-primary;
  text-align: center;
  width: 50%;
  color: rgba(0, 0, 0, 0.54);
}
