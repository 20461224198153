/// Fixing broken stage story box, unique styling ///

.stageStorySuggestion__box {
  width: 600px;
  height: 140px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 5000;
  background-color: white;
  border-radius: 25px;
}

.stageStorySuggestion__container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 6000;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 0;
  width: 100%;
  height: 100%;
  p {
    margin: 0;
    text-align: center;
  }
}

////////////////////////////////

.stageStorySuggestion {
  width: 600px;
  height: 140px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 5000;
  background-color: white;
  border-radius: 25px;
  p {
    margin: 0;
    text-align: center;
  }
}

.stageStorySuggestion__header {
  height: 70px;
  background-color: #26a69a;
  margin: 0;
  border-radius: 20px 20px 0 0;
}

.stageStorySuggestion__title {
  font-family: $font-primary;
  font-size: 2em;
  color: white;
  text-align: center;
  line-height: 2em;
}

.stageStorySuggestion__main {
  height: 310px;
  clear: both;
  width: 100%;
  margin: auto;
}

.stageStory__block {
  width: 33%;
  float: left;
  text-align: center;
  margin-top: 30px;
}

.stageStory__title {
  font-family: $font-primary;
  font-size: 1em;
  font-weight: 300;
}

.stageStory__image {
  background-color: #eeeeee;
  width: 50px;
  height: 35px;
  margin: auto;
}

.stageStorySuggestion__go-back {
  background-color: #eeeeee;
  height: 70px;
  bottom: 0;
  width: 300px;
  border-radius: 0 0 0 20px;
  padding: 0;
  text-align: center;
  float: left;
}

.stageStorySuggestion__to-stories {
  background-color: #ffd740;
  height: 70px;
  width: 300px;
  border-radius: 0 0 20px 0;
  padding: 0;
  float: left;
  text-align: center;
}

.stageStorySuggestion__close {
  color: rgba(0, 0, 0, 0.54);
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.stageStorySuggestion__continue {
  color: white;
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}
