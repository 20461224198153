// RELATED FILES:
//   _components.reading-stage-chooser.scss
//   _components.edit-archive-list.scss

.stage--edit__menu {
  margin: 0;
  padding: 0;
}

.stage--edit__title {
  color: $text-medium;
  font-size: 22px;
  margin: 0;
  max-width: 33.3333%;
  text-transform: uppercase;
}

.edit-notes__header {
  font-size: 18px;
  padding: 15px 0 0 40px;
  text-transform: uppercase;
  color: white;
}

.edit-notes__body {
  padding: 15px 15px 0 40px;

  textarea {
    border: 1px solid $text-border;
    font-family: $font-primary;
    font-size: 14px;
    min-height: 200px;
    min-width: 75%;
    resize: none;
  }
}

.edit-notes__buttons {
  padding: 15px 0 0 40px;
  width: 75%;
}

.advancement__menu {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}
