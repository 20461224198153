// RELATED:
//   _components.stimulus.scss
//   _components.timer.scss

$height--stageStory: 105%;
$padding--stageRight: 20px;

.stage {
  padding: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.stage--device-select {
    min-height: 100%;

    &__content {
      animation: slide 2s alternate;
    }
  }

  &.stage--edit {
    display: block;
    padding: 0;
    height: 100%;
  }

  &.stage--workspace {
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;

    &.stage--workspace--full {
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
    }
  }

  &.stage--story {
    padding: 0;
    height: 100%;
  }
}

.stage--story__gallery {
  width: 100%;
  height: $height--stageStory;
}

.stage__container {
  background-color: $white;
  margin-bottom: 60px;
}

.stage__top {
  display: flex;
  flex: 2 0 auto;
}

.stage__bottom {
  display: flex;
  justify-content: space-between;
}

// TODO: variablize common spacing
// TODO: DRY up stage__* classes
.stage__left {
  flex: 1 0 10%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.stage__right {
  flex: 1 0 10%;
  display: flex;
  justify-content: flex-end;
  padding: 0 $padding--stageRight 10px;

  @include respond-to(medium, width, up) {
    padding: 0 $padding--stageRight * 2 10px;
  }

  @include respond-to(medium, height, down) {
    padding-top: 10px;
  }

  @include respond-to(large, height, up) {
    padding-top: 30px;
  }
}

.stage__center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3 0 55%;
  margin: 0 auto;
  padding-bottom: 10px;

  &.orf-1 {
    display: block;
  }

  .container--reinforcement & {
    @include respond-to(medium, width, up) {
      flex: 3 0 50%;
      justify-content: space-between;
    }

    @include respond-to(large, width, up) {
      flex: 3 0 35%;
    }
  }
}

.stage__items {
  display: flex;
  width: 36%;

  @include respond-to(medium, width, up) {
    width: 33%;
  }
}

.stage__items--center {
  justify-content: space-between;
  width: 27%;

  @include respond-to(medium, width, up) {
    width: 33%;
  }
}

.stage__items--right {
  justify-content: flex-end;
  padding-right: $padding--stageRight;

  @include respond-to(medium, width, up) {
    padding-right: $padding--stageRight * 2;
  }
}

.stage__item {
  margin: auto;
  display: inline-block;

  .stage__left & {
    margin: 0;
  }
  .stage__left & + & {
    margin-top: 1rem;
  }

  .menu--assessment--story & {
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  &.timer {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    margin: 0;
    border-radius: 10px;
  }

  &.hidden {
    display: none;
  }
}

.stage__item-orfTimer {
  margin: auto;
  display: inline-block;
  text-align: center;
}

.stage__stimulus-ORF-finished {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  z-index: 10000;
}

.stage__timer {
  // NOTE: Related to the bottom spacing on .stage__itemTItle

  &.reinforcement {
    margin-bottom: 50%;
  }
}

.readingStageSuggestion {
  //remove when autoMode Implemented
  width: 50px;
  position: absolute;
  right: 0%;
  top: 15px;
  margin-right: 10px;
  z-index: 5000;
  background-color: white;
  opacity: 50%;
  border-radius: 20px;
  padding: 5px 10px;
}
