*,
*:after,
*:before {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

a {
  text-decoration: none;
  border: none;
}
