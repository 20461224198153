$borderColor--table: transparentize($white, 0.7);
$bg--rowHeader: $gray-translucent40;
$bg--cell: $gray-translucent20;
$bg--cellSelected: lighten($yellow, 20%);
$bg--cellHover: lighten($yellow, 35%);
$color--cellSelected: $yellow;

.table__title {
  color: white;
  font-size: 1.5em;
  margin-bottom: 25px;
  font-family: $font-primary;
  text-align: center;
}
.table__wrapper {
  margin-top: 60px;
}

.table__wrapper--overlay {
  max-height: 90vh;
  overflow-y: scroll;
}

// Table layout build with flexbox
.tableFlex__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $borderColor--table;

  .admin-notifications & {
    pointer-events: none;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  & .st-selected {
    color: $white;
    background-color: $color--cellSelected;
    font-weight: 700;
  }

  &:hover {
    cursor: pointer;

    .tableFlex__cell {
      color: $color--cellSelected;

      &.st-selected {
        color: $gray-dark;
        background-color: $color--cellSelected;
      }
    }
  }
}

.tableFlex__row--header {
  border: 0;

  &:hover {
    .tableFlex__cell {
      cursor: default;
      background-color: $bg--rowHeader;
    }
  }
}

.tableFlex__row--orf {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $borderColor--table;

  &:last-of-type {
    border-bottom: 0;
  }
}

.tableFlex__cell {
  font-family: $font-primary;
  flex: 1;
  padding: 1em 0;
  text-align: center;
  text-transform: uppercase;
  background-color: $bg--cell;
  color: $white;
  border-right: 1px solid $borderColor--table;
  display: flex;
  min-height: 70px;
  justify-content: center;
  flex-flow: column nowrap;

  .admin-notifications & {
    text-align: left;
    padding: 1em;
  }

  &.wide {
    flex-grow: 2;
  }

  &.tableFlex__cell--next {
    border-right: 0;
  }

  &:last-child {
    border-right: 0;
  }

  .tableFlex__row--header & {
    background-color: $bg--rowHeader;
    color: $text-light;
    border: 0;
  }

  .tableFlex__row--orf & {
    color: $black;
    background-color: $white;
  }

  .individual-conferences &:first-child {
    flex: 1.5;
  }
  .individual-conferences &:nth-child(2) {
    flex: 0.5;
  }
}

.tableFlex__data {
  margin: 0;
  line-height: 1.4;

  &.st-selected {
    color: $white;
    background-color: $color--cellSelected;
    font-weight: 700;
  }
}

.tableNav__next {
  background-image: url(../img/icon-next.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 21px;
  cursor: pointer;
  margin: auto;
}

.tableNav__back {
  background-image: url(../img/icon-back.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 21px;
  cursor: pointer;
  margin: auto;
}

.st-disabled {
  opacity: 0.05;
  cursor: default;
}
