$height--stimulus: 180px;

.stage__stimulus {
  font-family: $font-primary;
  font-size: 2em;
  background-color: $white;
  flex: 1 0 $height--stimulus;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.orf-1 {
    flex: 1;
    height: 760px;
  }

  &.orf-stimulus {
    flex: 1;
    height: 600px;
    position: relative;
  }

  &.st-flipped {
    .stimulus {
      margin-top: 75px;
      margin-bottom: 0;
    }
  }

  &.st-unflipped {
    .stimulus {
      //  margin-bottom: 75px;
      margin-top: 0;
    }
  }
}

.stage__stimulus--small,
.stage__stimulus--full {
  background-color: $white;
  display: flex;

  .synthetic & {
    background: $synthetic-stage;
  }

  .stimulus-cell { 
    font-size: 80px;
    line-height: 1.2;
    text-align: center;
    padding: 0 0.75em;
    color: $words-color;

    &.msw-list {
      display: flex;
      font-size: 5em;
      height: 214px;
      margin-top: 44px;
    }

    @include respond-to(medium, width, up) {
      padding: 0 1em;
    }
  }

  .synthetic & .stimulus-cell {
    color: $synthetic-decodable-words-color !important;
    
    .is-tricky {
      color: $synthetic-words-color;
    }
  }
  .synthetic .masterList__stimulus-display & .stimulus-cell {
    color: $synthetic-words-color !important;
  }
}

.stage__stimulus--small {
  flex: 1 0 auto;
  padding: 10px;
}

.stage__stimulus--full {
  flex: 3 0 auto;
}

.stimulus {
  margin-bottom: 75px;
  text-align: center;

  .stimulus-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
    font-size: 80px;
    line-height: 2em;
    width: 100%;
    color: $words-color;

    &:focus {
      outline: none;
    }

    @include respond-to(large, width, up) {
      padding: 0;
    }

    img {
      height: 1em;
      margin: 0 10px -20px;
    }
  }

  &.stimulus__gallery {
    outline: 0;
    margin: 0 auto;
    height: $height--stimulus; // controlling flickety height here
    width: 100%;

    &.ORF__stimulus {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      overflow: scroll;
    }

    .stimulus-cell {
      width: 100%;
      color: $words-color;

      &:focus {
        outline: none;
      }
    }

    .stimulus__ORF-firstgrade {
      color: rgba(0, 0, 0, 0.54);
      font-size: 19px;
      text-align: left;
      padding: 0 1rem 0 88px;
      line-height: 24px;
      overflow: auto;
      text-indent: 20px;
      max-width: 60%;

      .hundredth-word {
        padding: 0 2px 0 8px;
        border-radius: 5px;
        border: 1px solid #ffca28;
        margin-right: 4px;
      }

      &.orf-1__student-view {
        // height: 550px;
        font-size: 24px;
        line-height: 36px;
        padding: 2rem;
      }

      &.orf-print span.error {
        text-decoration: line-through;
        color: $black;
      }

      &.orf-print {
        line-height: 32px;
        color: $black;
        width: 100%;
        margin: auto;
        text-transform: none;
        overflow: visible;
      }

      span.error {
        color: $yellow;
      }

      span.last {
        color: $green;
      }
    }

    .stimulus__ORF-instructions {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .stimulus--orfs {
    outline: 0;
    margin: 0 auto;
    height: $height--stimulus; // controlling flickety height here
    width: 100%;
  }

  &.stimulus--letters {
    font-size: 0.55em;

    &.stimulus__gallery {
      height: auto;
      min-height: $height--stimulus;
    }
  }

  &.stimulus--words {
    font-size: 0.35em;

    @include respond-to(large, width, up) {
      font-size: 0.5em;
    }
  }

  // &.stimulus--tiles {
  //   max-height: 340px;

  //   &.stimulus--tiles--reverse {
  //     flex-direction: row-reverse;

  //     .stimulus--tile-choices__wrapper {
  //       align-self: flex-start;
  //     }

  //     .stimulus__tile-column--onset {
  //       @extend .slideInLeft;
  //       li {
  //         background-color: darken($tiles-color, 20%);
  //       }
  //     }

  //     .stimulus__tile-column--rime {
  //       @extend .slideInDown;
  //       li {
  //         background-color: $tiles-color;
  //       }
  //     }

  //     .stimulus--onsets-rimes {
  //       margin-right: 75px;
  //       margin-left: 0;
  //     }

  //     .stimulus--tile-choices {
  //       margin-left: 75px;
  //       margin-right: 0;
  //     }
  //   }
  // }

  // .stimulus--onsets-rimes {
  //   bottom: 0;
  //   height: 100%;
  //   margin-left: 75px;

  //   .stimulus__tile-column {
  //     overflow-y: scroll;
  //     -webkit-overflow-scrolling: touch;

  //     li {
  //       background-color: $tiles-color;
  //       color: $text-light;
  //       font-size: 100px;
  //       font-family: $font-primary;
  //       height: 120px;
  //       line-height: 100px;
  //       margin-bottom: 2px;
  //       padding-left: 3px;
  //     }
  //   }

  //   .stimulus__tile-column--onset {
  //     @extend .animated;
  //     @extend .slideInDown;
  //     text-align: right;
  //     max-width: 165px;
  //   }

  //   .stimulus__tile-column--rime {
  //     @extend .animated;
  //     text-align: left;

  //     li {
  //       background-color: darken($tiles-color, 20%);
  //     }
  //   }
  // }

  .stimulus--tile-choices {
    color: $white;
    font-size: 32px;
    margin-right: 75px;
    max-height: 340px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .stimulus--tile-choices__wrapper {
      align-self: flex-end;
      overflow: auto;
      width: 45%;
    }

    .tile-choice {
      background-color: darken($tiles-color, 20%);
      border-bottom: 1px solid lighten($tiles-color, 20%);
      height: 50px;
      cursor: pointer;
    }
  }
}

.stimulus__ORF-pagination {
  font-size: 12px;
  padding: 10px;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}

.stage__stimulusDisplay {
  flex: 3 0 auto;
  padding: 10px;
}

.stage__stimulusTiles {
  flex: 1 0 auto;
  display: flex;
  padding: 10px;
  background-color: lighten($gray-chateau, 25%);
  align-items: center;
}

.matrix-ORF {
  margin-bottom: 32px;
  border-top: 1px solid white;
}

.stimulus__ORFstimulus {
  font-size: 85px !important;
}

.hundred-word-marker {
  color: #ffca28;
  position: fixed;
  margin-top: -11px;
  margin-left: -61px;
  padding-right: 21px;
  font-size: 7px;
  z-index: 10000000;
  text-align: right;
  background-color: white;
  line-height: 15px;
}

.js-choose-passage {
  margin: 1rem 0 0;
}

.stimulus__badge {
  background-color: #ffca28;
  color: $text-dark;
  padding: 3px 5px;
  font-size: 12px;
  margin: 1rem;
  border-radius: 3px;
  line-height: 1;
  display: inline-block;
  text-align: center;
  text-indent: 0;
  text-transform: capitalize;
  pointer-events: none;
}

.no-indent {
  text-indent: 0;
}

.stimulus__image {
  padding: 1rem 88px 1rem 1rem;
  max-width: 40%;
}
.stimulus__orf-passage {
  p {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    text-align: left;
    line-height: 1;
    text-indent: 0 !important;
  }
}
