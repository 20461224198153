// Freehand controls

$maxWidth--controls: 50px;
$size--color: 25px;

.controlsWrapper {
  max-width: $maxWidth--controls;
  z-index: 100; // needs to be on top of the canvas

  .whiteboard__canvas--inverted & {
    align-self: flex-end;
    margin-bottom: 20px;
  }
}

.controls {
  padding: 15px;
  background-color: $gray-light;
  display: inline-block;
}

.controls__divider {
  height: 1px;
  width: 100%;
  background-color: $gray-med;
  margin: 15px 0;
}

.controls__control--color {
  display: none;
  height: $size--color;
  width: $size--color;
  margin: 0 auto 15px;
  border: 1px solid $gray-lighter;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &.is--selected {
    display: block;
  }
}

.controls__control--stroke {
  background-color: $white;
  border-radius: 50%;
  margin: 0 auto 15px;
  border: 1px solid $gray-lighter;

  &:hover,
  &:focus {
    background-color: lighten($gray-light, 25%);
    cursor: pointer;
  }

  &.is--selected {
    background-color: lighten($gray-light, 15%);
  }
}

.controls__control--clear {
  margin: 0 auto;
  width: 30px;
}

.controls__icon {
  height: 30px;
  width: 30px;
  fill: $white;

  &:hover,
  &:focus {
    fill: lighten($gray-light, 25%);
    cursor: pointer;
  }
}
