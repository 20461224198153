// NOTE: Testing out some utility flexbox classes. Will expand usage if it
// feels like it is working.

.flex--display {
  display: flex;
}

.flex--justifyContent--flexEnd {
  justify-content: flex-end;
}

.flex--justifyContent--center {
  justify-content: center;
}

.flex--direction--column {
  flex-direction: column;
}

.flex--alignItems--center {
  align-items: center;
}
