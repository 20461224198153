// TODO: resolve vertical alignment
.timer {
  font-size: 0.8em;
  display: inline-block;
  color: $black;
  letter-spacing: 0.1em;
  vertical-align: middle;

  &__buttonIconReset {
    height: 25px;
    width: 25px;
  }
}

.timer__display {
  display: inline-block;
}

.timer__time {
  cursor: pointer;

  &:hover {
    color: $yellow;
  }
}

.timer__dropdowns {
  display: inline-block;
}

.timer__buttons {
  display: inline-block;
  margin-left: 5px;

  .button--small {
    margin: -4px 0 0 3px; // for proper visual alignment with select menus
  }
}
