.story {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $white;
  z-index: 100;
}

.story__image {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  img {
    width: 56%;
    margin-left: 10%;
    padding-top: 10px;
    margin-top: 2%;

    @media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
      display: none;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
      display: none;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      display: block;
    }
  }

  &.st-flipped {
    img {
      width: 55%;
      padding-top: 0;
    }
  }
}

.story__page {
  width: 100%;
  height: 100%;
}

.story__text {
  font-family: $font-primary;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 40px;
  padding: 5px 20px 0;

  @media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
    margin: auto;
    margin-top: 142px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    margin: auto;
    margin-top: 142px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    font-family: $font-primary;
    font-size: 24px;
    margin-bottom: 20px;
    padding: 5px 20px 0;
  }

  &.story__text--teacher {
    display: none;
    background: $white;
    text-align: center;
    margin-top: 10px;
    font-size: 0.8em;
    overflow: visible;

    &.st-visible {
      display: block;
    }
  }
}

.story__text__number {
  font-size: 14px;
  color: $text-medium;
  margin-right: 10px;
}

@keyframes hide_unknown {
  from {
    color: rgba(0, 0, 0, 255);
  }
  to {
    color: rgba(0, 0, 0, 0);
  }
}

.story__text__word {
  cursor: pointer;

  &.story__text--unknown {
    animation-name: hide_unknown;
    animation-duration: 1s;
    animation-iteration-count: 2;
    animation-direction: alternate;
  }
}

.story__popup--mastery {
  position: absolute;
  z-index: 1000;
  border: 3px solid #ffca28;
  background-color: rgb(66%, 66%, 66%);
  border-radius: 21px;

  &--icon-close {
    width: 34px;
  }
}
.story__flip {
  position: absolute;
  bottom: 40px;
  right: 10px;
  font-size: 22px;

  .icon-text {
    width: auto;
  }

  .icon-text__icon {
    fill: $gray-chateau;
    cursor: pointer;
  }
}

.story__reinforcement {
  position: absolute;
  bottom: 17%;
  height: 117px;
  width: 200px;
  margin: auto;
  text-align: center;
  left: 2%;
  font-size: 22px;
  border: 3px solid #ffca28;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 21px;

  h2 {
    line-height: 1;
  }
  
  .icon-text {
    width: auto;
  }

  .icon-text__icon {
    fill: $gray-chateau;
    cursor: pointer;
  }
}

.story__footer {
  height: 100px;
}

.st-flipped {
  -webkit-transform: scale(-1, -1);
  transform-style: preserve-3d;
  transition: all 0.2s linear;
}

.st-unflipped {
  -webkit-transform: scale(1, 1);
  transform-style: preserve-3d;
  transition: all 0.2s linear;
}
