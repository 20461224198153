body {
  -webkit-touch-callout: none;

  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;

  /* prevent webkit from resizing text to fit */
  user-select: none;
  -webkit-user-select: none;
  background-color: rgba(black, 0.1);
  padding: 0;
  margin: 0;
}

/* Portrait layout (default) */

.app {
  background: url(../img/logo.png) no-repeat center top;

  /* 170px x 200px */
  position: absolute;

  /* position in the center of the screen */
  left: 50%;
  top: 50%;
  height: 50px;

  /* text area height */
  width: 225px;

  /* text area width */
  text-align: center;
  padding: 180px 0 0 0;

  /* image height is 200px (bottom 20px are overlapped with text) */
  margin: -115px 0 0 -112px;

  /* offset vertical: half of image height and text area height */

  /* offset horizontal: half of text area width */
}

.pageContent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pageContent--login {
  &.st-loading {
    cursor: wait;
  }
}

.st-inactive {
  display: none;
}

// create a new class for this to DRY it up
.workspace,
.story,
.container {
  &.container--overlay {
    background: $bg-overlayDark;
    left: 0;
    opacity: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    animation-name: slideUp;
    animation-duration: 0.5s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;
  }
}

.workspace {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container--flex {
  display: flex;
}

.container--application {
  background-color: #e7e9f5;
}

.statusContainer {
  width: 1024px;
  margin: 0 auto;
}

@keyframes slideUp {
  from {
    top: 768px;
  }
  to {
    top: 0;
  }
}

.container--management {
  height: 768px;
  width: 100%;
  margin: auto;
}

.container--reinforcement {
  display: flex;
  background: #e7e9f5;
  padding-bottom: 60px;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.container--orf-report {
  background-color: $white;
  z-index: 9000;
  position: absolute;
  top: 0;
  left: 0;
  height: 200%;
  width: 100%;
  padding: 8%;
  text-align: left;
  .tableFlex__row--orf {
    text-align: left;
  }
}

.tableFlex__cell {
  text-align: left;
  &.tableFlex__cell--orf {
    text-align: left;
    float: left;
    clear: both;
  }
}

.report__bold {
  font-weight: 700;
}

.container__side {
  flex: 1;
  display: flex;
}

.container__side--right {
  @include respond-to(large, width, down) {
    flex: 2;
  }
}

.container__middle {
  flex: 4;
  max-width: 55%;

  @include respond-to(large, width, up) {
    max-width: 50%;
    flex: 3;
  }
}

.container__actions {
  position: fixed;
  top: 180px;
  right: 15px;

  @include respond-to(medium, width, up) {
    right: 35px;
  }

  @include respond-to(large, width, up) {
    top: 180px;
    right: 50px;
  }
}

.container__action {
  &.margin {
    margin-bottom: 30px;
  }
}

.container__addStudent {
  max-width: 85%;
  margin: auto;

  @include respond-to(medium, width, up) {
    max-width: 65%;
  }

  @include respond-to(large, width, up) {
    max-width: 50%;
  }
}

.container--logging {
  max-height: 1024px;
  overflow: scroll;
}

.container--roster {
  padding: 2rem;
  flex-flow: column nowrap;
  align-items: center;

  .button {
    width: auto;
    margin: 5px auto;
  }
}
