// -----------------------------------------------------------------------------
// Table of Contents
//
//  - Settings
//  - Tools
//  - Base
//  - Objects
//  - Components
//  - Vendor
//  - Utilities
//  - Working
//
// -----------------------------------------------------------------------------

// NOTE: The descriptions below currently document the desired result for each
// section. More organization work needs to be done to achieve that result.

// -----------------------------------------------------------------------------
// !-- Settings
//
// Variables that are used throughout the app and other SCSS settings.
// -----------------------------------------------------------------------------

@import "00-settings/colors";
@import "00-settings/grid";
@import "00-settings/type";

// -----------------------------------------------------------------------------
// !-- Tools
//
// Functions and mixins used throughout the project.
// -----------------------------------------------------------------------------

@import "01-tools/functions";
@import "01-tools/mixins";

// -----------------------------------------------------------------------------
// !-- Base
//
// Starts with our reset and includes any other base element-level styling.
// -----------------------------------------------------------------------------

@import "02-base/reset";
@import "02-base/body";
@import "02-base/type";

// -----------------------------------------------------------------------------
// !-- Objects
//
// Smaller, more abstract pieces of the UI. These are compiled together to
// build our Components.
// -----------------------------------------------------------------------------

@import "03-objects/icon-text";
@import "03-objects/icons";
@import "03-objects/layout";
@import "03-objects/tab";
@import "03-objects/tile";
@import "03-objects/overlay";
@import "03-objects/sliders";
@import "03-objects/buttons";
@import "03-objects/magnet";
@import "03-objects/toggles";
@import "03-objects/selects";

// -----------------------------------------------------------------------------
// !-- Components
//
// The unique pieces of the UI. These are groups of elements that function
// together as a unit.
// -----------------------------------------------------------------------------

@import "04-components/nav";
@import "04-components/canvas";
@import "04-components/chant";
@import "04-components/controls";
@import "04-components/drawer";
@import "04-components/menus";
@import "04-components/stimuli";
@import "04-components/stimulus";
@import "04-components/matrix";
@import "04-components/timer";
@import "04-components/reading-level-chooser";
@import "04-components/reading-level-uk";
@import "04-components/reading-stage-chooser";
@import "04-components/edit-archive-list";
@import "04-components/mgmt-table";
@import "04-components/panels";
@import "04-components/orientationlock";
@import "04-components/errorLog";
@import "04-components/sound-buttons";
@import "04-components/chat";
@import "04-components/debug";

// -----------------------------------------------------------------------------
// !-- Vendor
//
// Files that are created by others. We can also place overrides to third-party
// libraries here as well.
// -----------------------------------------------------------------------------

@import "05-vendor/animate";
@import "05-vendor/flickety-overrides";

// -----------------------------------------------------------------------------
// !-- Utilities
//
// These files hold classes that are used to perform a very specific function.
// Classes here typically hold some sort of "state" and end up overriding
// styles created elsewhere.
// -----------------------------------------------------------------------------

@import "06-utilities/visibility";
@import "06-utilities/text";

// TODO: organize and re-factor into Objects and Components
@import "views.stage";
@import "views.login";
@import "views.story";
@import "views.edit-student";
@import "views.device-select";
@import "views.whiteboard";
@import "views.pubnub-message";
@import "views.stage-story-suggestion";
@import "views.reading-stage-advancement-suggestion";
@import "views.orfReminder";
@import "views.orfFinish";
@import "views.orfPrevious";
@import "views.dialog-end-session";
@import "views.dialog-dirty-data";

// -----------------------------------------------------------------------------
// !-- Working
//
// This file is intended to be our CSS scratchpad. Use this file to work on new
// ideas and explore different approaches. Styles should not live here for long
// before they are deleted or properly organized.
// -----------------------------------------------------------------------------

@import "working.scss";
