.dialogDirtyDataContainer p {
  color: white;
}

.dialogDirtyDataContainer button {
  border: 2px solid white;
  background-color: $mastered;
}

.dialogDirtyData__overlay {
  background-color: rgba(0, 0, 0, 0.54);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5000;
  left: 0;
  top: 0;
}

.dialogDirtyData {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 25%;
  left: 50%;
  margin-top: -70px;
  margin-left: -300px;
  p {
    margin: 0;
    text-align: center;
  }
}

.dialogDirtyData__header {
  height: auto;
  width: 600px;
  margin: 0;
  background: white;
  border-radius: 20px 20px 0 0;
  color: rgba(0, 0, 0, 0.54);
}

.dialogDirtyData__main {
  height: 310px;
  clear: both;
  width: 100%;
  margin: auto;
}

.dialogDirtyData__block {
  width: 33%;
  float: left;
  text-align: center;
  margin-top: 30px;
}

.dialogDirtyData__title {
  margin: 1rem 5em 0;
  line-height: 1.5em;
  padding-top: 0.5em;
  text-align: justify;
  font-family: $font-primary;
  font-size: 1em;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
}

.dialogDirtyData__image {
  background-color: #eeeeee;
  width: 50px;
  height: 35px;
  margin: auto;
}

.dialogDirtyData__message {
  width: 74%;
  margin: auto;
}

.dialogDirtyData__list {
  margin-top: 30px;
  text-align: left;
}
.highlight__info {
  color: #ec407a;
  font-size: 1.5em;
}

.dialogDirtyData__logout-container,
.dialogDirtyData__cancel-container {
  height: 70px;
  bottom: 0;
  padding: 0;
  text-align: center;
  float: left;
  cursor: pointer;
}

.minorOptions__container {
  width: 80%;
  margin: 20px auto 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.dialogDirtyData__ok-container {
  background-color: #009688;
  height: 70px;
  width: 600px;
  border-radius: 0 0 20px 20px;
  padding: 0;
  float: left;
  text-align: center;
  cursor: pointer;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-property: background-color;
  &:hover {
    background-color: #01695f;
  }
}

.dialogDirtyData__logout,
.dialogDirtyData__cancel {
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-property: color;
  font-size: 1.5rem;
  color: #009688;
  &:hover {
    color: #01695f;
  }
}

.dialogDirtyData__ok {
  color: white;
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

ul.dialogDirtyData__list {
  li {
    margin-bottom: 10px;

    &:first-of-type {
      font-weight: 900;
    }
  }
}
