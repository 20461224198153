.pubNubMessage {
  width: 330px;
  position: absolute;
  left: 50%;
  top: 15px;
  margin-left: 60px;
  z-index: 100;
  background-color: white;
  opacity: 50%;
  border-radius: 20px;
  padding: 5px 10px;

  p {
    margin: 0;
    text-align: center;
  }
}

.pubNubError p {
  color: red;
}

.pubNubSuccess p {
  color: green;
}
