.loading-login__logo {
  margin: 5% auto 0;
  width: 468px;
  height: auto;
}

.loading-login__header {
  margin: 0 auto;
  width: 468px;
  margin-top: 40px;

  progress {
    border: 0;
    height: 22px;
    width: 468px;
    border-radius: 9px;
    position: relative;

    &::-webkit-progress-bar {
      background-color: $white;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &::-webkit-progress-value {
      background-color: $orange;
    }
  }
}

.loading-login__footer {
  color: $orange;
  text-align: center;
  margin-top: 40px;

  .loading-login__title {
    font-size: 24px;
  }

  .loading-login__forgot {
    margin: 30px auto;
    color: $orange;
    background: transparent;
    display: block;
    border: 0;
  }
}

.login__header {
  margin-top: 15%;
}

.login__logo {
  width: 300px;
  height: 150px;
}

.login__field {
  width: 240px;
  margin-right: 10px;
  height: auto;
  font-size: 14px;
  line-height: 1;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid $white;
  background: transparent;
  color: $white;
  border-radius: 0;

  &:last-of-type {
    margin-right: 0;
  }
}

// login error css
.login__error {
  color: $warning;
  font-size: 18px;
  text-align: center;
  display: none;
}

.login__field--email,
.login__field--password {
  &::placeholder {
    color: $primary;
    font-size: 14px;
  }
}

.teachermate-logo {
  width: 175px;
  height: 30px;
  margin: 30px auto;
  text-align: center;
}

.footer--login {
  background-image: url(../img/footer-rainbow.svg);
  background-repeat: no-repeat;
  height: 20px;
  width: 2048px;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: footer-slide 75s linear infinite forwards;
}

@keyframes footer-slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1024px);
  }
}

.version-number--nav {
  display: block;
  font-size: 10px;
  text-transform: lowercase;
}

.version-number--login {
  display: block;
  position: absolute;
  color: $gray-chateau;
  background-color: transparentize($black, 0.5);
  top: 0;
  right: 0;
}

#reset-app {
  margin: 10px;
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 90px;
  background-color: #fcb131;
  color: black;
  font-size: 12px;
  border: 0;
  padding: 7px;
  text-align: center;
  min-height: unset;
  text-transform: uppercase;
}
