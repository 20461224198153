$font-primary: "Andika", sans-serif;

h2 {
  font-family: $font-primary;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  color: $menu-button;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("../fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("../fonts/MaterialIcons-Regular.woff") format("woff"),
    url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}
