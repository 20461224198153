.matrix {
  font-family: $font-primary;
}

.matrix__menu,
.matrix__student-selector {
  width: 100%;
}

.matrix__menu {
  // placeholder
}

.matrix__stimuli {
  width: 100%;
  min-height: 100px;
  max-height: 160px;
  overflow-y: scroll;

  @include respond-to(small, height, up) {
    max-height: 225px;
  }

  // @include respond-to(medium, height, up) {
  //   max-height: 300px;
  // }

  @include respond-to(large, height, up) {
    max-height: 450px;
  }

  .st-active {
    display: flex;
  }
}

.matrix__student-selector {
  // placeholder
}

.matrix--reading-strategies {
  left: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  transition: all 0.3s ease-in;
  height: 40px;
  opacity: 0.5;

  &.st-active {
    bottom: 0;
    height: 310px;
    opacity: 1;
  }
}

.matrix__inner {
  background: #e7e9f5;
  height: 100%;
}
