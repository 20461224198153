// -----------------------------------------------------------------------------
// Table of Contents
//
//  - Named Colors
//  - Application Colors
//  - Text
//  - Links
//  - Backgrounds
//  - Buttons
//  - Menu
//  - Matrix
//  - Magnets
//  - Settings
//  - Toggles
//  - Sliders
//  - Whiteboard
//  - Panels
//
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// !-- Named Colors
// -----------------------------------------------------------------------------

// RULE: DO NOT USE THESE VARIABLES. ONLY USE THE SEMANTIC ONES BELOW
$white: #ffffff;
$black: #232425;
$pureBlack: #000000;
$gray-dark: #605e5d;
$gray-med: lighten(#000000, 54%);
$gray-light: #9ca1a5;
$gray-lighter: #eeeeee;
$gray-chateau: #a7a9ab;
$gray-translucent40: rgba(0, 0, 0, 0.4);
$gray-translucent20: rgba(0, 0, 0, 0.2);
$gray-translucent10: rgba(0, 0, 0, 0.1);
$iron: #e6e7e8;
$gray-border: #f2f2f2;
$gray-background: #f9f9f9;

$orange: #ff7043;
$red: #e64c3b;
$green: #4eb96e;
$yellow: #ffca28;
$yellow-light: #fdd835;
$blue-light: #44c5f2;
$blue: darken($blue-light, 20%);
$purple: #ec407a;
$purple-dark: #5c6bc0;
$turquoise: #1de9b6;

// -----------------------------------------------------------------------------
// !--  Application Colors
// -----------------------------------------------------------------------------

$words-color: $turquoise;
$synthetic-stage: #f5f0e5;
$synthetic-words-color: #025642;
$synthetic-decodable-words-color: #7e057a;
$phrases-color: $purple;
$tiles-color: $blue-light;
$primary: $yellow;
$warning: $red;
$mastered: $turquoise;
$go: $turquoise;
$learning: $yellow;
$needs-work: $purple; 
$needs-work-synthetic: $yellow; 
$connected: $turquoise;
$disconnected: $red;
$dirty: $yellow;

// -----------------------------------------------------------------------------
// !--  Text
// -----------------------------------------------------------------------------

$text-dark: $black;
$text-light: $white;
$text-medium: $gray-light;
$text-med-light: lighten($gray-light, 20%);
$text-med-dark: $gray-med;
$text-active: $yellow;
$text-black: transparentize($pureBlack, 0.46);
$login-placeholder: $turquoise;

// -----------------------------------------------------------------------------
// !--  Links
// -----------------------------------------------------------------------------

$link-settings: $yellow;

// -----------------------------------------------------------------------------
// !--  Backgrounds
// -----------------------------------------------------------------------------

$bg-drawer: $black;
$bg-dark: $black;
$bg-light: $white;
$bg-overlayDark: transparentize($black, 0.1);

// -----------------------------------------------------------------------------
// !--  Buttons
// -----------------------------------------------------------------------------

$button-bg: $yellow;
$button-text-light: $white;
$button-text-dark: $gray-med;
$button-text: $white;
$button-text-active: $yellow;

// -----------------------------------------------------------------------------
// !--  Menu
// -----------------------------------------------------------------------------

$menu-button: $black;

// -----------------------------------------------------------------------------
// !--  Matrix
// -----------------------------------------------------------------------------

$matrix-tile: $black;
$matrix-tab: $black;
$matrix-border: $gray-dark;

// -----------------------------------------------------------------------------
// !--  Magnets
// -----------------------------------------------------------------------------

$magnet-onset: $turquoise;
$magnet-rime: $purple;
$magnet-custom: $blue-light;
$magnet-bank: $gray-lighter;

// -----------------------------------------------------------------------------
// !--  Settings
// -----------------------------------------------------------------------------

$settings-letterNames: $yellow-light;
$settings-sightWords: $purple;
$settings-onsetRimes: $turquoise;
$settings-affixes: $orange;
$settings-leveledTexts: $purple-dark;
$settings-transparent-bg: transparentize($pureBlack, 0.8);

// -----------------------------------------------------------------------------
// !--  Toggles
// -----------------------------------------------------------------------------

$toggle: $white;
$toggle--active: $yellow;

// -----------------------------------------------------------------------------
// !--  Sliders
// -----------------------------------------------------------------------------

$slider-active: $yellow;

// -----------------------------------------------------------------------------
// !--  Whiteboard
// -----------------------------------------------------------------------------

$whiteboard: $white;

// -----------------------------------------------------------------------------
// !--  Panels
// -----------------------------------------------------------------------------

$panel-background: $gray-translucent20;

// -----------------------------------------------------------------------------
// !--  Borders
// -----------------------------------------------------------------------------

$text-border: $gray-border;
