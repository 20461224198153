.slider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0.5em 2.5em 0.5em 1.5em;
}

.slider__label {
  margin-right: 20px;
  color: white;
  flex: 1 0 25%;
}

.slider__gaps {
  margin-top: 5px;
  flex: 1 0 70%;

  // Override some jquery-ui styling within our .slider context
  .ui-widget-content {
    border: 0;
    background: none;
  }

  .ui-corner-all {
    border-radius: 50%;
  }

  .ui-slider-handle:hover {
    cursor: ew-resize;
  }

  .ui-slider-horizontal .ui-slider-range {
    top: 4px;
    height: 15%;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    border: 0;
    background: none;
    background-color: $bg-light;
    font-weight: normal;
    color: $text-black;
  }

  .ui-state-active {
    background-color: $text-black;
  }

  .ui-state-focus {
    outline-style: none;
    box-shadow: 0 0 5px $slider-active;
  }

  // Must nest range colros to override ui-widget-header styling
  .slider__range--letterNames {
    background: $settings-letterNames;
  }

  .slider__range--sightWords {
    background: $settings-sightWords;
  }

  .slider__range--onsetRimes {
    background: $settings-onsetRimes;
  }

  .slider__range--affixes {
    background: $settings-affixes;
  }

  .slider__range--leveledTexts {
    background: $settings-leveledTexts;
  }
}

// Slider Key

.key {
  width: 96%;
  margin: 8px auto 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.key__cell {
  flex: 1 0 15%;
  background-color: $settings-transparent-bg;
  margin: 10px;
  padding: 8px;
}

.key__label {
  color: $text-light;
  font-size: 12px;
  text-align: center;
  margin-bottom: 8px;
}

.key__example {
  height: 2px;
  margin-bottom: 10px;
}

.key__example--letterNames {
  background-color: $settings-letterNames;
}

.key__example--sightWords {
  background-color: $settings-sightWords;
}

.key__example--onsetRimes {
  background-color: $settings-onsetRimes;
}

.key__example--affixes {
  background-color: $settings-affixes;
}

.key__example--leveledTexts {
  background-color: $settings-leveledTexts;
}
