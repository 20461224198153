// Custom Selects

.selectWrapper select {
  cursor: pointer;
  font-size: 1.15em;
  height: 30px;
  background: transparentize($white, 0.85);
  color: $text-light;
  width: 50px;
  border: none;
  padding: 5px;
}
