.errorLogs {
    textarea {
        width: 80%;
        height: 80vh;
    }
    .button--primary {
        width: auto !important;
        float: right;
        margin: 1rem;
    }
}