.menu--tabs {
  &--edit {
    background: $iron;
  }
}

.menu--tab {
  background-color: transparentize($black, 0.3);
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  color: $white;
  cursor: pointer;
  font-size: 11px;

  line-height: 40px;
  text-transform: uppercase;
  text-align: center;

  .menu__number {
    text-align: center;
    font-size: 2em;

    .st-changed {
      &:before {
        content: "(";
      }

      &:after {
        content: ")";
      }
    }
  }

  .menu__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .menu__icon {
    fill: $white;
    width: 15%;
    height: 40px;
    margin-left: auto;

    .icon-edit,
    .icon-close {
      width: 32px;
      height: auto;
      vertical-align: middle;
    }

    &.icon-close:before {
      vertical-align: middle;
    }
  }

  &.st-active {
    color: $white;
    background-color: $matrix-tab;
  }

  // these are the tabs found on the student edit screen
  .menu--tabs--edit & {
    background-color: $iron;
    max-width: 20%;
    border: 0;

    &.st-selected {
      background-color: $gray-background;
      color: $text-dark;
      font-family: $font-primary;
      font-size: 14px;
      border: 0;
    }

    &:last-child {
      border: 0;
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
    }
  }

  // these are the tabs that are at the bottom of the matrix- the student selector tabs
  .menu--tabs--student-selector & {
    background-color: transparentize($black, 0.3);
    position: relative;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    cursor: pointer;
    flex: 1;
    text-align: center;
    line-height: 1.7;

    .menu__number {
      line-height: 1.5;
    }

    .menu__title {
      text-transform: capitalize;
      font-size: 0.85em;
      margin-bottom: 0.5em;
    }

    .menu__icon {
      height: 20px;
      width: 15px;
      fill: $white;
      margin: 0 0.25em;
    }

    .icon-edit {
      opacity: 0;
      height: auto;
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: linear;
      transition-delay: 0.3s;
      margin-top: -5px;
    }

    &:last-child {
      border-right: 0;
    }

    &.st-active {
      color: $primary;

      .icon-edit {
        opacity: 1;
      }
    }
  }

  &.menu--tab--reading-stage {
    padding: 0 1%;

    img {
      width: 20px;
    }

    .st-changed-stage {
      color: $yellow;
    }
  }

  &.icon-triangle-up,
  &.icon-triangle-down {
    font-size: 32px;
    flex: none;
    width: 5%;

    &:before {
      vertical-align: middle;
    }
  }

  .menu__label {
    //  background-color: #3d3e40;
    display: block;
    width: 100%;
    height: 100%;

    &.st-active {
      //  background-color: $matrix-tab;
      color: $primary;
      font-weight: 700;
    }
  }
  // these are the tabs that "float" to the top of the edit screen
  &.menu--tab--edit-student {
    color: $text-light;
    width: 0;
    border: 0;
    position: absolute;
    top: 59px;
    height: 40px;
    line-height: 40px;

    .menu__title {
      vertical-align: middle;
    }

    .menu__number {
      border-right: 1px solid rgba($white, 0.5);
      text-align: center;
    }

    .menu__icon {
      width: 32px;
      margin-left: auto;
    }
  }
}
