.readingStageAdvancementSuggestion p {
  color: white;
}

.readingStageAdvancementSuggestion button {
  border: 2px solid white;
  background-color: $mastered;
}

.readingStageAdvancement__overlay {
  background-color: rgba(0, 0, 0, 0.54);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5000;
  left: 0;
  top: 0;
}

.readingStageAdvancement {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  z-index: 1000;
  top: 50%;
  left: 50%;
  margin-top: -70px;
  margin-left: -300px;
  p {
    margin: 0;
    text-align: center;
  }
}

.readingStageAdvancement__header {
  height: 70px;
  width: 600px;
  background-color: #26a69a;
  margin: 0;
  border-radius: 20px 20px 0 0;
}

.readingStageAdvancement__title {
  color: white;
  text-align: center;
  line-height: 1.5em;
  padding-top: 0.5em;
  font-family: $font-primary;
  font-size: 1em;
  font-weight: 300;
}

.readingStageAdvancement__main {
  height: 310px;
  clear: both;
  width: 100%;
  margin: auto;
}

.readingStageAdvancement__block {
  width: 33%;
  float: left;
  text-align: center;
  margin-top: 30px;
}

.readingStageAdvancement__image {
  background-color: #eeeeee;
  width: 50px;
  height: 35px;
  margin: auto;
}

.readingStageAdvancement__go-back {
  background-color: #eeeeee;
  height: 70px;
  bottom: 0;
  width: 300px;
  border-radius: 0 0 0 20px;
  padding: 0;
  text-align: center;
  float: left;
}

.readingStageAdvancement__to-stories {
  background-color: #ffd740;
  height: 70px;
  width: 300px;
  border-radius: 0 0 20px 0;
  padding: 0;
  float: left;
  text-align: center;
}

.readingStageAdvancement__close {
  color: rgba(0, 0, 0, 0.54);
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.readingStageAdvancement__continue {
  color: white;
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.ra-help__button {
  font-size: 1em;
  color: rgba(255, 255, 255, 1);
  border-radius: 68%;
  text-decoration: none;
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.3s ease-out;
  padding: 3px 10px;
  background-color: $yellow;
}
.ra-help__button:hover {
  color: $yellow;
  background-color: white;
}

.ra-help__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.ra-help__overlay:target {
  visibility: visible;
  opacity: 1;
}

.ra-help__popup {
  margin: 70px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.ra-help__popup h2 {
  margin-top: 0;
  color: rgba(0, 0, 0, 0.54);
}
.ra-help__popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
}
.ra-help__popup .close:hover {
  color: $yellow;
}
.ra-help__popup.content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .reading-advancement-help {
    width: 70%;
  }
  .ra-help__popup {
    width: 70%;
  }
}

.ra-help__header {
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 10px;
}

.ra-help__content {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.54);
}
