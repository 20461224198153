.orfStudentName {
  color: #26a69a;
}

.orfFinish__bar {
  display: flex;
  justify-content: space-around;
}

.orfFinish__subBar {
  display: flex;
  justify-content: center;
  margin: 20px 0 10px;
  height: 44px;
}

.orfFinish {
  height: 106px;
  width: 170px;

  &.totalwpm {
    background-color: #ffc400;
  }

  &.totaltime {
    background-color: #00e5ff;
  }
  &.wrongwords {
    background-color: #ec407a;
  }
  &.correctwpm {
    background-color: #1de9b6;
  }
  &.reportdate {
    background-color: #42a5f5;

    select {
      color: white;
      font-family: "Andika", sans-serif;
      font-size: 2.6rem;
      line-height: 1;
      margin: 0 auto;
      outline: 0;
      background: transparent;
      border: none;

      option {
        background: $gray-dark;
      }
    }
  }

  &.subStat {
    background-color: transparent;
    color: rgba(0, 0, 0, 54);
    height: 44px;
    &.rightBorder {
      border-right: 1px solid rgba(0, 0, 0, 0.3);
    }
    &.hundredth-word {
      padding: 0 2px 0 8px;
      border-radius: 5px;
      border: 1px solid #ffca28;
      margin-right: 4px;
    }
  }
}

.orfFinish__number {
  color: white;
  font-family: $font-primary;
  font-size: 1.8em;
  line-height: 0.2em;
  margin: 0 auto 0;
  position: relative;

  &.correct {
    line-height: 1.9em;
  }

  &.substat {
    line-height: 1.5em;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.54);
  }
}

.orfFinish__numberTitle {
  color: white;
  font-family: $font-primary;
  font-size: 0.45em;
  line-height: 0;

  &.substat {
    color: rgba(0, 0, 0, 0.54);
  }
}

.orfFinish__header {
  height: 70px;
  width: 750px;
  background-color: #666666;
  margin: 0;
  border-radius: 20px 20px 0 0;
}

.orfFinish__saveButton {
  background-color: #1de9b6;
  height: 70px;
  bottom: 0;
  width: calc(750px / 3);
  border-radius: 0 0 20px 0;
  padding: 0;
  text-align: center;
  float: left;

  &.disabled { 
    pointer-events: none; 
    a { color: #ddd; }
  }
}

.orfFinish__printButton {
  background-color: #42a5f5;
  height: 70px;
  bottom: 0;
  width: calc(750px / 3);
  padding: 0;
  text-align: center;
  float: left;
}

.orfFinish__cancelButton {
  background-color: #666666;
  height: 70px;
  bottom: 0;
  width: calc(750px / 3);
  border-radius: 0 0 0 20px;
  padding: 0;
  text-align: center;
  float: left;
}

.orfFinish__close {
  color: white;
  font-size: 2em;
  font-family: $font-primary;
  line-height: 2em;
  cursor: pointer;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  cursor: pointer;

  &.orfBegin__adjustment {
    vertical-align: middle !important;
  }

  &.clearsearch {
    vertical-align: middle !important;
    font-size: 0.7em;
  }

  &.togglehundred {
    font-size: 15px;
    margin-right: -5px;
    margin-left: -5px;
  }

  &.msw-search {
    float: left;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.54);
    vertical-align: middle !important;
    line-height: 22px;
    margin-left: 5px;

    &.close {
      display: none;
    }
  }

  &.orfFinish__adjustment {
    color: white !important;
  }

  &.orfBack {
    color: rgba(0, 0, 0, 0.54);
    font-size: 65px;

    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &.orfNext {
    color: rgba(0, 0, 0, 0.54);
    font-size: 65px;

    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
