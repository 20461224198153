.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $bg-overlayDark;
  z-index: 200;
  animation-name: slideUp;
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
}

.overlay__close {
  height: 60px;
  width: 60px;
}
