.header--main-nav {
  background: $white;
  height: 60px;
  text-transform: uppercase;
}

.nav--main {
  .nav--main__teacher:after {
    border-right: 1px solid $gray-light;
    content: "";
    height: 10px;
    padding-right: 20px;
  }
}

.nav__item {
  display: inline-block;
  margin-left: 25px;

  @include respond-to(medium, width, up) {
    margin-left: 50px;
  }
}

.nav__link {
  font-family: $font-primary;
  font-size: 1.2em;
  display: inline-block;
  color: $gray-med;
  cursor: pointer;
}

.current-page {
  color: $primary;
  cursor: default;
}

.nav--main__logo {
  font-family: $font-primary;
  font-size: 1.2em;
  color: $gray-med;
  margin-left: auto;
  margin-right: 25px;
  text-align: right;

  @include respond-to(medium, width, up) {
    margin-right: 50px;
  }

  span {
    color: $primary;
  }
}

.nav--reinforcement {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparentize($black, 0.5);
}

.navList {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
}

.navList__item {
  color: $white;
  cursor: pointer;
  padding: 10px 0;

  &:hover,
  &:focus,
  &.st-active {
    color: $text-active;
  }
}

.navList__item__msw-assessment {
  color: $white;
  cursor: pointer;
  padding: 10px 0;
  width: 200px;
  text-align: center;

  &:hover,
  &:focus,
  &.st-active {
    background-color: $text-active;
  }
}

.navStacked {
  // placeholder
}

.navStacked--home {
  position: fixed;
  top: 120px;
  left: 10px;

  @include respond-to(medium, width, up) {
    left: 25px;
  }

  @include respond-to(large, width, up) {
    top: 120px;
    left: 50px;
  }
}

.navStacked__item {
  margin-bottom: 40px;

  @include respond-to(large, width, down) {
    .icon-text__title {
      display: none;
    }

    .icon-text--large {
      width: 80px;
    }
  }

  @include respond-to(medium, width, down) {
    .icon-text--large {
      width: 70px;
    }
  }

  @include respond-to(small, width, down) {
    .icon-text--large {
      width: 50px;
    }
  }
}

.settingsNav {
  width: 100%;
  height: 50px;
  display: block;
  margin: 0 auto 2em;

  &.settingsNav_color {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.settingsNav__grid {
    float: left;
    width: 206px;
    height: 100%;
    margin-right: 5px;
  }

  &.settingsNav__selection {
    font-family: $font-primary;
    font-size: 1em;
    line-height: 50px;
    color: white;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin-right: 5px;

    &:active {
      background-color: #ffc400;
    }
    &:hover {
      background-color: #ffc400;
    }
    &.st-selected {
      background-color: rgba(#ffc400, 1);
    }
  }
}

.breacrumbNav {
  margin-left: 80px;
}

.breadcrumbNav__type {
  font-family: $font-primary;
  letter-spacing: 1px;
  color: $orange;
  cursor: pointer;
  float: left;
  padding: 6px;

  &.restore-settings {
    float: right;
    margin-right: 80px;
    background-color: $orange;
    border-radius: 5px;
    width: 200px;
    text-align: center;
  }
}

.restore-settings__button {
  font-size: 1em;
  letter-spacing: 1px;
  color: white;
  font-family: $font-primary;
}
