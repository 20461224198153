$border--tile: 1px solid $black;

.stimuli-tiles--reading-strategies {
  display: flex;
  padding: 10px 20px;
}

.tile {
  cursor: pointer;

  .matrix & {
    border-bottom: $border--tile;
  }

  .stage__stimulusTiles & {
    margin: 2px;
  }

  .stimuli-tiles--stories__tiles & {
    width: 33.3333%;
    border-right: $border--tile;

    &:nth-child(3n) {
      border-right: 0;
    }
  }

  .stimuli-tiles--letters & {
    width: 7.6923%;
    border-right: $border--tile;

    &:nth-child(13n) {
      border-right: 0;
    }
  }

  .synthetic .stimuli-tiles--letters .rs-4 & {
    width: 50%;
  }

  .stimuli-tiles--onsets & {
    width: 25%;
    border-right: $border--tile;

    &:nth-child(4n) {
      border-right: 0;
    }
  }

  .stimuli-tiles--rimes & {
    width: 14.2857%;
    border-right: $border--tile;

    &:nth-child(7n) {
      border-right: 0;
    }

    &:nth-child(7n + 1) {
      border-left: $border--tile;
    }
  }

  .stimuli-tiles--words & {
    width: 11.1111%;
    border-right: $border--tile;

    &:nth-child(9n) {
      border-right: 0;
    }

    &:last-of-type {
      border-right: $border--tile;
    }
  }

  .stimuli-tiles--reading-strategies & {
    margin: 0 10px;
    border: $border--tile;
  }
}

.tile__title {
  font-size: 20px;
  display: block;
  color: $black;
  padding: 8px 0;

  @include respond-to(medium, height, up) {
    font-size: 24px;
    padding: 10px 0;
  }

  .stimuli-tiles--reading-strategies & {
    font-size: 0.8em;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 4px;
    height: 100px;
  }

  &.st-selected {
    background: $white;
    color: $turquoise;
  }
  
  .synthetic &.st-selected {
    background: $synthetic-stage;
    color: $synthetic-words-color;
  }

  &.st-assessmentMenuTileSelected {
    border: 1px solid $white;
  }

  .synthetic &.st-mastered,
  &.st-mastered {
    background: $turquoise;
    color: $white;
  }

  .synthetic &.st-needs_work,
  &.st-needs_work {
    background: $needs-work;
    color: $white;
  }

  #applicationContainer.synthetic &.st-needs_work {
    background: $needs-work-synthetic;
  }

  &.st-learning {
    background: $yellow;
    color: $white;
  }
}

.ORF-grade-selection {
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin: 32px auto;
  width: 536px;
}

.ORF-grade__button {
  background-color: white;
  border-radius: 8px;
  font-family: $font-primary;
  height: 48px;
  line-height: 48px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  width: 220px;

  &.selected {
    background-color: #ffc400;
    color: white;
  }
}
