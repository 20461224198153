.debug {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 25%;
  background: #ffffffdd;
  z-index: 1000;
  padding: 1rem;
  border: 1px solid gray;

  input {
    width: 100%;
    padding: 10px;
  }
  button {
    background: $yellow;
    padding: 10px;
    margin-top: 10px;
  }
}