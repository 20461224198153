.reading-stage-chooser {
  max-width: 60%;
  margin: 60px auto;

  &.st-initial {
    .st-selected:before {
      background-color: $turquoise;
    }
  }
  .js-editContainer & {
    float: left;
  }
}

.reading-stage-chooser__button {
  border-radius: 8px;
  display: block;
  max-width: 65px;
  text-align: center;
}

.reading-stage__title {
  color: $text-medium;
  font-size: 12px;
  margin: 0;
  min-width: 16%; // magic number?
  text-transform: uppercase;
}

.reading-stage__choice {
  position: relative;
  text-align: center;
  color: $black;
  font-size: 42px;
  max-width: 75px;
  margin: auto;
  font-family: $font-primary;
  z-index: 1;
  padding: 7px;

  &.current {
    color: $yellow;
  }

  &.readingLevelCircle {
    font-size: 10px;
    line-height: calc(4rem - 4px);
    text-transform: uppercase;
    text-align: center;
    color: white;
    padding: 0;

    &.current {
      color: white;
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    border: 1px solid transparent;
    border-radius: 10%;
    display: inline-block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 75px;
    height: 75px;
  }

  // &:hover::before,
  &.st-selected:before {
    background: $primary;
    color: black;
    z-index: -10;
  }

  // &:hover {
  //   z-index: 10;
  //   color: $text-light;
  // }

  &.reading-stage__choice--large {
    font-size: 54px;
    display: block;
    padding: 0;

    &:before {
      width: 135px;
      height: 135px;
    }
  }
}

.reading-stage-setup {
  width: 40%;
  .js-editContainer & {
    float: left;
  }
}

.reading-stage-setup__current {
  width: 50%;
  float: left;
  padding: 15px;
  border-right: 1px solid #bbb;
}

.reading-stage-setup__initial {
  width: 50%;
  float: left;
  padding: 15px;
  border-right: 1px solid #bbb;
  .reading-stage__choice--large {
    &.st-selected:before {
      background: $turquoise;
    }
  }
}

.reading-stage-setup__title {
  text-align: center;
  text-transform: uppercase;
  color: black;
}

.advancement-type {
  font-size: 0.8em;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

.reading-stage__options {
  margin-bottom: 31px;
  padding: 0 46px;
  text-align: center;
  width: 100%;
}

.reading-level__options {
  text-align: center;
  width: 100%;
}

.advancement__border-bottom {
  border-bottom: 1px solid #bbb;
  height: 50%;
  width: 100%;
  margin: auto;
  display: flex;
}

.ORF-begin__button {
  color: $yellow;
  cursor: pointer;
}
