.canvasContainer {
  overflow: hidden;
  position: relative;
  margin-bottom: 70px;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.canvas--freehand {
  cursor: url(../img/cursors/pencil.png), pointer;
}

.masterList__stimulus-display {
  height: 200px;
  margin-top: 10%;

  &.sight-words {
    height: 234px;
    margin-top: 0;
  }
}
