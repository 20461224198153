.device-select__title {
  color: $primary;
  font-size: 72px;
  text-align: center;
  animation: slide 2s 0.5s alternate backwards;
}

.device-select__option {
  width: 740px;
  height: 100%;
  path {
    fill: lightgray;
  }

  &:hover {
    color: $primary;
    path {
      fill: $primary;
    }
  }

  .select--teacher {
    width: 350px;
    margin-right: -66px; // magic number

    &:hover {
      fill: $primary;
    }
  }
  .select--student {
    width: 288px;
  }

  .icon-text__title {
    font-size: 35px;
    font-weight: 100;
  }
}
