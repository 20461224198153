$size--icon: 34px;

.icon-text {
  text-align: center;
  color: $menu-button;
  display: inline-block;
  width: 75px;

  &.reinforcement-width {
    width: 106px;
  }

  &.icon-disabled {
    cursor: default;
  }
}

.icon-text--large {
  width: 150px;
  font-family: $font-primary;
}

.icon-text__title {
  display: block;
  font-size: 10px;
  margin-top: 5px;
  text-transform: capitalize;
  letter-spacing: 0.1em;
  color: $black;
  text-align: center;
  clear: both;

  .icon-text--large & {
    font-family: $font-primary;
    text-transform: uppercase;
  }

  &.orf-instruction {
    color: white;

    &.accuracy {
      margin-top: 15px;
    }
  }

  @include respond-to(small, height, up) {
    font-size: 12px;
  }

  &.icon-disabled {
    cursor: default;
  }
}

.icon-text__icon {
  fill: $black;
  width: 32px;
  height: 32px;
  cursor: pointer;

  .icon-text--large & {
    width: 44px;
    height: 44px;
  }
}

.story__text-popup--mastery {
  color: white;
  font-size: 20px;
  width: 100px;
  text-align: center;
  height: 43px;

  display: block;

  margin: 5px;
  border: wheat 2px solid;
  border-radius: 10px;

  @include respond-to(small, height, up) {
    width: 100px;
    height: 30px;
  }

  &.title__text-mastered {
    background-color: $go;
  }

  &.title__text-learning {
    background-color: $learning;
  }

  &.title__text-needs-work {
    background-color: $needs-work;
  }

  #applicationContainer.synthetic &.title__text-needs-work {
    background-color: $needs-work-synthetic;
  }

  &.title__text-clear {
    background-color: white;
    color: black;
  }
}

// TODO: move this to its own place
.icon-circle {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin: 10px auto 5px;

  @include respond-to(small, height, up) {
    width: 34px;
    height: 34px;
    margin: 20px auto 5px;
  }

  &.st-selected {
    &:after {
      content: "";
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: inline-block;
      text-align: center;
      margin: -3px -3px;

      @include respond-to(small, height, up) {
        width: 40px;
        height: 40px;
      }
    }

    &.icon-mastered {
      // box-shadow: inset 0 0 20px darken($go, 20%);
      &:after {
        border: 2px solid $go;
      }
    }
    &.icon-learning {
      // box-shadow: inset 0 0 20px darken($learning, 20%);
      &:after {
        border: 2px solid $learning;
      }
    }
    &.icon-needs-work {
      // box-shadow: inset 0 0 20px darken($needs-work, 20%);
      &:after {
        border: 2px solid $needs-work;
      }
    }

    #applicationContainer.synthetic &.icon-needs-work {
      &:after {
        border: 2px solid $needs-work-synthetic;
      }
    }
  }

  &.icon-mastered {
    background-color: $go;
  }
  &.icon-learning {
    background-color: $learning;
  }
  &.icon-needs-work {
    background-color: $needs-work;
  }
  #applicationContainer.synthetic &.icon-needs-work {
    background-color: $needs-work-synthetic;
  }
  &.icon-clear {
    background-color: white;
  }
  &.icon-disabled {
    background-color: $gray-med;
    cursor: default;
  }
  &.icon-enabled {
    background-color: $button-bg;
  }
  &.icon-loading {
    background-image: url("../img/icons/ajax-loader.gif");
    background-repeat: no-repeat;
  }
}

.iconExit {
  display: inline-block;
  fill: rgba(0, 0, 0, 0.54);
  height: 1em;
  stroke-width: 0;
  width: 1em;
}

.ORF__timer {
  color: white;
  font-family: $font-primary;
  font-size: 2em;
  text-align: center;
}

.ORF__start-timer,
.ORF__stop-timer {
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: $font-primary;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 10px;
  padding: 10px 0;
  text-transform: uppercase;
  width: 120px;
}

.ORF__start-timer.stop {
  display: none;
}

.ORF__stop-timer.start {
  display: none;
}

.ORF__finish-message {
  color: white;
  padding: 10px 0;
  background-color: #ffc400;
  font-family: $font-primary;
  font-size: 12px;
  margin-top: 20px;
}

.accuracy__score {
  color: $yellow;
  font-family: $font-primary;
  font-size: 2em;
  text-align: center;
}

.master-list__sort {
  font-family: $font-primary;
  text-transform: uppercase;
  font-size: 11px;
  float: right;
  margin-right: 15px;
  margin-top: 0;
  color: rgba(0, 0, 0, 0.54);

  &:active,
  :hover {
    color: $blue-light;
  }

  &.active {
    color: $blue-light;
  }
}

.master-list__block-header {
  margin: 20px 20px 10px 20px;
  padding-bottom: 8px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  font-size: 12px;
  display: inline-block;
  width: 90%;
}

.master-list__word-block {
  width: 80%;
  display: block;
  margin: auto;
  clear: both;
}

.master-list__clear-search {
  float: right;
  clear: both;
  font-size: 0.7em;
  margin-right: 10px;
  margin-top: 10px;
}
