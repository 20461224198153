.connection {
  z-index: 210;
  background-image: url(../img/icons/nowifi.png);
  background-repeat: no-repeat;
  background-size: 32px 32px;
  position: absolute;
  margin-left: auto;
  left: 0;
  width: 50px;
  height: 50px;

  &.online {
    display: none;
  }

  &.offline {
    display: inline;
  }
}

.pubNubConnection {
  z-index: 211;
  position: absolute;
  top: 5px;
  left: 8px;

  &.offline {
    display: none;
  }

  &.online {
    display: inline;
  }

  &.icon-connected {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 18px solid $connected;
  }
  &.icon-disconnected {
    cursor: pointer;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 18px solid $disconnected;
  }
}

.localDataState {
  &.icon-dirty {
    cursor: auto;
    width: 30px;
    height: 30px;
    margin: 0 0 -8px;
    display: block;
    background-image: url(../img/icons/sync-error.svg);
    background-size: contain;
  }

  &.clean {
    display: none;
  }
}
