$radius--toggle: 40px;

.toggle {
  $width: 38px;
  $height: 38px;

  position: relative;
  width: $width;
  height: $height;
  border-radius: $radius--toggle;
  border: 2px solid rgba(255, 255, 255, 0.7);

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    width: $width - 5px;
    height: 34px;

    &:checked {
      ~ .toggle__button {
        background-color: $toggle--active;
      }
    }
  }
}

.toggle__button {
  $position: 5px;
  $radius: 50%;
  $size: 25px;

  display: block;
  position: absolute;
  z-index: 4;
  top: $position;
  left: $position;
  width: $size;
  height: $size;
  border-radius: $radius;
  transition: left 0.25s ease;
}

.toggle__labels {
  $position--leftRight: 12px;
  $position--top: 6px;

  font-size: 0.6em;
  position: absolute;
  top: $position--top;
  z-index: 2;
  width: 100%;
  color: $toggle;
  text-transform: uppercase;
  line-height: 0.6em;

  &:before {
    position: absolute;
    left: $position--leftRight;
    line-height: $position--leftRight;
    font-size: 0.6em;
  }

  &:after {
    position: absolute;
    right: $position--leftRight;
    line-height: 0.8em;
    font-size: 0.6em;
  }
}

.toggle__bg {
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $radius--toggle;
  background-color: transparentize($toggle, 0.5);
}

input[type="number"] {
  background: none !important;
  border: none !important;
  font-family: $font-primary !important;
  color: white !important;
  font-size: 0.75em !important;
  text-align: right !important;
}

.auto-refresh-toggle {
  margin-top: -30vh;
}

////toggle sliders////

.toggle-slider {
  $width: 75px;
  $height: 21px;

  position: relative;
  width: $width;
  height: $height;
  border-radius: $radius--toggle;

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    width: $width - 5px;
    height: 34px;

    &:checked {
      ~ .toggle__button-slider {
        left: 35px;
        background-color: $toggle--active;
      }

      ~ .toggle__bg-slider {
        background-color: transparentize($toggle--active, 0.5);
      }
    }
  }
}

.toggle__button-slider {
  $position: 6px;
  $radius: 50%;
  $size: 34px;

  display: block;
  position: absolute;
  z-index: 4;
  top: -$position;
  left: $position;
  width: $size;
  height: $size;
  background-color: $toggle;
  border-radius: $radius;
  transition: left 0.25s ease;
}

.toggle__labels-slider {
  $position--leftRight: 12px;
  $position--top: 6px;

  font-size: 0.6em;
  position: absolute;
  top: $position--top;
  z-index: 2;
  width: 100%;
  color: $black;
  text-transform: uppercase;
  line-height: 0.6em;

  &:before {
    content: "On";
    position: absolute;
    left: $position--leftRight;
    line-height: 0.8em;
    font-size: 0.6em;
  }

  &:after {
    content: "Off";
    position: absolute;
    right: $position--leftRight;
    line-height: 0.8em;
    font-size: 0.6em;
  }
}

.toggle__bg-slider {
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $radius--toggle;
  background-color: transparentize($toggle, 0.5);
}

.auto-refresh-toggle-slider {
  margin-top: -30vh;
}
