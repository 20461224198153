.sound-button {
  position: relative;
  &.dot:after {
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    top: calc(100% - 27px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: #666666;
  }
  &.dash:after {
    content: '';
    height: 6px;
    width: calc(100% - 10px);
    position: absolute;
    top: calc(100% - 25px);
    left: 50%;
    transform: translateX(-50%);
    background: #666666;
  }
  &.line {
    min-width: 55px;
    &::after {
      content: '';
      height: 40px;
      width: calc(300% - 20px);
      position: absolute;
      top: calc(100% - 25px);
      left: 50%;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 230 80' style='enable-background:new 0 0 230 80;' xml:space='preserve'%3E%3Cpath class='st0' fill='none' stroke='%23666666' stroke-width='14' stroke-miterlimit='10' d='M222.6,0c0,37.2-48.2,67.3-107.6,67.3S7.4,37.2,7.4,0'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}