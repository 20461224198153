$magnet-zindex: 2;

.magnets {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border-right: 3px solid rgba(0, 0, 0, 0.05);
  max-height: 100%;
  &:last-of-type {
    border-right: none;
  }

  .whiteboard__bank--inverted & {
    flex-direction: row-reverse;
  }
}

.magnet {
  z-index: $magnet-zindex;

  &.ui-draggable {
    cursor: move;
  }

  .is--mirror & {
    cursor: not-allowed;
  }

  .whiteboard__bank & {
    margin: 3px;
  }
}

.magnet--letters,
.magnet--onsets {
  background-color: $magnet-onset;
}

.magnet--rimes {
  background-color: $magnet-rime;
}

.magnet--custom {
  background-color: $magnet-custom;
}

.magnet__inner {
  font-family: $font-primary;
  font-size: 36px;
  line-height: 1;
  padding: 15px 18px;
  margin: 0;
  color: $white;
  text-align: center;

  .whiteboard__canvas--inverted &,
  .whiteboard__bank--inverted & {
    transform: scale(-1, -1);
  }
}
