$chant-word-font-height: 60px;

.chant__carousel {
  text-align: center;
}

.chant__word {
  font-family: $font-primary;
  letter-spacing: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $chant-word-font-height * 2; // must be taller than top of bounce

  @include respond-to(medium, width, up) {
    letter-spacing: 5px;
  }
}

.chant__segments {
  display: inline-block;
  font-weight: normal;
  font-size: $chant-word-font-height;

  @include respond-to(medium, width, up) {
    font-size: $chant-word-font-height * 1.5;
  }
}

.chant__segment {
  display: block;
  float: left;
}

.chant__segment--onset {
  &.is--highlighted {
    color: $magnet-onset;
  }
}

.chant__segment--rime {
  &.is--highlighted {
    color: $magnet-rime;
  }
}

.chant__controls {
  text-align: center;
  padding-top: 2em;
}

.chant__buttonWrapper {
  display: inline-block;
}

.chant__button {
  cursor: pointer;
  margin: 0 10px;

  &.is--hidden {
    display: none;
  }

  &.is--disabled {
    opacity: 0.3;
  }
}

.chant__buttonText {
  color: $gray-med;
}

.chant__buttonIcon {
  fill: $gray-med;
  height: 4em;
  width: 4em;
}
