.u-text-center {
  text-align: center !important;
}

.u-300 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
}

.u-600 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
}

.u-400 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
}
