body {
  font-family: $font-primary;
}

a {
  color: $white;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

fieldset {
  border: 0;
}
