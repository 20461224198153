.orfPrevAssessments {
  padding: 2rem;

  table {
    font-size: 16px;
    text-align: center;
    width: 100%;
    border-collapse: collapse;
  }
  h6 {
    margin: 0 0 1rem;
  }
  th,
  td {
    border: 1px solid $gray-lighter;
  }
  th {
    background-color: $gray-translucent20;
  }
}
