.readingLevelCircle {
    display: inline-block;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 2px solid transparent;
    font-size: 10px;
    line-height: calc(4rem - 4px);
    text-transform: uppercase;
    text-align: center;
    color: white;
    padding: 0;

    &.small {
        height: 1.25rem;
        width: 1.25rem;
        line-height: 1.25rem;
        border: none;
        vertical-align: 5px;
    }

    &.current {
        border-color: white;
    }

    &.pink { background: #e8439d;}
    &.magenta { background: #ce2eb0;}
    &.red { background: #ef5350;}
    &.yellow { background: #ffca28;}
    &.blue { background: #42a5f5;}
    &.green { background: #66bb6a;}
    &.orange { background: #ffa726;}
    &.turquoise { background: #26a69a;}
    &.purple { background: #7e57c2;}
}